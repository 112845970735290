<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>Add a new user</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <form class="user-form">
            <div class="grid-x grid-padding-x">
              <div class="cell small-12 medium-6">
                <label>Email address<sup class="text-red">*</sup></label>
                <input required type="text" v-model="user.email_address">
                <label>Password<sup class="text-red">*</sup></label>
                <input required type="password" v-model="user.password">
                <label>Re-enter Password<sup class="text-red">*</sup></label>
                <input required type="password" v-model="user.passwordCheck">
                <label>Forename<sup class="text-red">*</sup></label>
                <input required type="text" v-model="user.first_name">
                <label>Surname<sup class="text-red">*</sup></label>
                <input required type="text" v-model="user.last_name">
                <label>City &amp; Guilds No</label>
                <input type="text" v-model="user.city_guilds_enrolment_number">
                <label>Notes</label>
                <vue-editor
                  id="notesEditor"
                  v-bind:editorToolbar="customToolbar"
                  v-model="user.notes"
                  placeholder="Notes text"
                  rows="12">
                </vue-editor>
              </div>
              <div class="cell small-12 medium-6">
                <label>File name (1)</label>
                <input type="text" v-model="user.fileOneName">
                <div class="file-one"></div>
                <div><p>{{ filenameOne }}</p></div>
                <label>File name (2)</label>
                <input type="text" v-model="user.fileTwoName">
                <div class="file-two"></div>
                <div><p>{{ filenameTwo }}</p></div>
                <label>File name (3)</label>
                <input type="text" v-model="user.fileThreeName">
                <div class="file-three"></div>
                <div><p>{{ filenameThree }}</p></div>
                <hr />
                <label>User level</label>
                <select required v-model="user.userlevel">
                  <option value="0">Please choose</option>
                  <option value="1">Admin</option>
                  <option value="2">Tutor</option>
                  <option value="3">Learner</option>
                  <option value="4">IQA/EQA</option>
                </select>
                <label>Tutor groups</label>
                <label
                  v-for="(group, index) in tutorGroups"
                  v-bind:key="index">
                  <input
                    type="checkbox"
                    name="tutor_group"
                    :value="group.id"
                    v-model="user.tutorGroups">
                    {{ group.name }}
                </label>
                <label>Courses</label>
                <label><input v-model="user.assessing" type="checkbox">Assessing Certificate</label>
                <label><input v-model="user.assessing_award" type="checkbox">Assessing Award</label>
                <label><input v-model="user.iqa" type="checkbox">IQA</label>
                <button class="button">Add user</button>
                <div class="UppyProgressBar"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="clearMessage">X</span>
      <p class="message-text">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

const Uppy = require('@uppy/core');
const UppyForm = require('@uppy/form');
const Transloadit = require('@uppy/transloadit');
const ProgressBar = require('@uppy/progress-bar');
const FileInput = require('@uppy/file-input');

export default {
  name: 'AddUser',
  data() {
    return {
      tutorGroups: [],
      user: {
        first_name: '',
        last_name: '',
        email_address: '',
        password: '',
        userlevel: 0,
        notes: '',
        city_guilds_enrolment_number: '',
        fileOneName: '',
        fileTwoName: '',
        fileThreeName: '',
        tutorGroups: [],
        assessing: false,
        iqa: false,
        assessing_award: false,
      },
      filenameOne: '',
      filenameTwo: '',
      filenameThree: '',
      message: '',
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: '1' }, { header: '2' }],
        ['link'],
      ],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    getTutorGroups() {
      axios.post(`/tutorGroups/getGroups.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.tutorGroups = response.data.tutorGroups;
        });
    },
    saveUser(result) {
      if (this.user.password === this.user.passwordCheck) {
        this.user.files = result;
        axios.post(`/users/createAccount.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.user)
          .then((response) => {
            if (response.data.response === true) {
              this.message = 'The new user has been added successfully';
              this.resetUser();
            } else {
              this.message = 'The new user could not be added - please check the details';
            }
          });
      } else {
        this.message = 'The passwords entered do not match. Please check.';
      }
    },
    resetUser() {
      this.user = {
        first_name: '',
        last_name: '',
        email_address: '',
        password: '',
        type: 0,
        notes: '',
        fileOneName: '',
        fileTwoName: '',
        fileThreeName: '',
      };
    },
    clearMessage() {
      this.message = '';
    },
  },
  mounted() {
    this.getTutorGroups();
    Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: (1000000 * 1000),
        maxNumberOfFiles: 3,
        minNumberOfFiles: 0,
      },
    })
      .use(UppyForm, {
        target: '.user-form',
        multipleResults: true,
        triggerUploadOnSubmit: true,
        submitOnSuccess: false,
      })
      .use(FileInput, {
        id: 'FileInputOne',
        target: '.file-one',
        pretty: true,
        inputName: 'user-file-one',
      })
      .use(FileInput, {
        id: 'FileInputTwo',
        target: '.file-two',
        pretty: true,
        inputName: 'user-file-two',
      })
      .use(FileInput, {
        id: 'FileInputThree',
        target: '.file-three',
        pretty: true,
        inputName: 'user-file-three',
      })
      .on('file-added', (res) => {
        if (this.filenameOne === '') {
          this.filenameOne = res.name;
        } else if (this.filenameOne !== '' && this.filenameTwo === '') {
          this.filenameTwo = res.name;
        } else {
          this.filenameThree = res.name;
        }
      })
      .use(ProgressBar, {
        target: '.UppyProgressBar',
        hideAfterFinish: false,
      })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: 'ad27d2c2cad8454fba2791c04c58c00e',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
      })
      .on('complete', (result) => {
        this.saveUser(result);
      });
  },
};
</script>
